
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRouter } from "vue-router";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import useFilters from "../common/useFilters";
import { download, searchStatements } from "@/api/statement.api";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  isLoaded: boolean;
  items: [];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  showInactive: boolean;
}
export default defineComponent({
  name: "GuarantorList",
  props: ["patientId"],
  components: { PaginationUi },
  setup(props) {
    const router = useRouter();
    const { orderList, putFiltersToUrl, getFiltersFromUrl } = useFilters();
    orderList.value = [
      {
        name: "File",
        key: "sentDate",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Statement ID",
        key: "statementId",
        isFilter: true,
        keyword: null,
        IsAdvancedOrderBy: true,
      },
      {
        name: "Sent",
        key: "sentDate",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Amount Due",
        key: "amountDue",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoaded: false,
      items: [],
      keyword: "",
      orderBy: "statementId",
      IsAdvancedOrderBy: true,
      isDecr: true,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "statementId";
      data.isDecr = true;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      getFiltersFromUrl();
      window.localStorage.removeItem("AddGuarantor");
      organizationId.value = getOrganization();
      await getAll();
      data.isLoaded = true;
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      putFiltersToUrl();

      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        keyword: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.value.map((item) => {
            return item.key;
          }),
          keyword: data.keyword,
        },
        PatientId: props.patientId,
      };

      const res = await searchStatements(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    function selectItem(item) {
      window.localStorage.removeItem("AddGuarantor");
      router.push({
        path:
          "/patientAccounts/patient/" +
          props.patientId +
          "/guarantor/" +
          item.id,
      });
    }

    function newGuarantor() {
      window.localStorage.removeItem("AddGuarantor");
      router.push({
        path: "/patientAccounts/patient/" + props.patientId + "/guarantor/",
      });
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getPDF(statement) {
      await download(statement);
    }

    return {
      organizationId,
      clearSearch,
      data,
      selectItem,
      orderList,
      newGuarantor,
      search,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      getPDF,
    };
  },
});
